import * as React from 'react';
import { Grid } from 'react-flexbox-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faSchool, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import SectionTitle from 'components/section-title/section-title';
import Markdown from 'components/markdown/markdown';
import { useTimeline } from 'hooks/timeline.hook';
import {
    timeline,
    timeline__wrapper,
    timeline__item,
} from './timeline.module.scss';

const Timeline = (): JSX.Element => {
    const items = useTimeline();
    const iconLookup = (icon: string): IconDefinition => {
        switch (icon) {
            case 'faBriefcase': return faBriefcase;
            case 'faSchool': return faSchool;
            default: throw new Error(`No icon exists with the name: ${icon}`);
        }
    };

    return (
        <div className={timeline}>
            <Grid>
                <SectionTitle title="Work experience and education" align="center" />
                <VerticalTimeline layout="1-column" className={timeline__wrapper}>
                    { items.map(({ id, title, subtitle, content, date, icon }) => (
                        <VerticalTimelineElement
                            key={`timeline-item-${id}`}
                            className={`${timeline__item} vertical-timeline-element--work`}
                            contentStyle={{
                                background: '#fff',
                                color: '#30231d',
                                padding: '30px',
                                boxShadow: '0 9px 24px rgba(0, 0, 0, .06)',
                            }}
                            contentArrowStyle={{ borderRight: '7px solid #fff' }}
                            date={date}
                            icon={<FontAwesomeIcon icon={iconLookup(icon)} />}
                            iconStyle={{ background: '#009688', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title">{title}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{subtitle}</h4>
                            <Markdown source={content} container />
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            </Grid>
        </div>
    );
};

export default Timeline;