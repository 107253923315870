import { useStaticQuery, graphql } from "gatsby";

export const useAboutMe = () => {
    const { directus }: Queries.DirectusAboutMeQuery = useStaticQuery(graphql`
        query DirectusAboutMe {
            directus {
                about_me {
                    content
                }
            }
        }
    `);

    return directus.about_me;
}