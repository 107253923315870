import * as React from 'react';

import Markdown from 'components/markdown/markdown';
import { useAboutMe } from 'hooks/about-me.hook';
import { aboutMe } from './about-me.module.scss';

const AboutMe = () => {
    const { content } = useAboutMe();

    return (
        <div className={aboutMe}>
            <Markdown source={content} container />
        </div>
    );
};

export default AboutMe;