import * as React from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'react-flexbox-grid';

import PageTitle from 'components/page-title/page-title';
import Timeline from 'components/timeline/timeline';
import Section from 'components/section/section';
import AboutMe from 'components/about-me/about-me';
import CallToAction from 'components/call-to-action/call-to-action';

const AboutPage = () => (
    <>
        <PageTitle title="About me" />

        <Grid>
            <Section>
            <AboutMe />
            </Section>
        </Grid>
        <Section colour="concrete" style={{ marginBottom: 0 }}>
            <Timeline />
        </Section>
        <Section style={{ margin: 0, padding: 0 }}>
            <CallToAction slug="download-cv" />
        </Section>
    </>
);

export const Head = ({ data }: any) => (
    <>
        <title>{data.directus.pages.title}</title>
        <meta name="description" content={data.directus.pages.description} />
        <meta name="robots" content={data.directus.robots} />
    </>
);

export const query = graphql`
    query {
        directus {
            pages(filter: { slug: { _eq: "about" }}) {
                title
                description
                robots
            }
        }
    }
`;

export default AboutPage;