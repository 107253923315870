import { useStaticQuery, graphql } from "gatsby";

export const useTimeline = () => {
    const { directus }: Queries.DirectusTimelineQuery = useStaticQuery(graphql`
        query DirectusTimeline {
            directus {
                timeline {
                    id
                    sort
                    slug
                    heading
                    subheading
                    content
                    date
                    icon
                }
            }
        }
    `);

    return directus.timeline;
}